.Wrapper{
  position:sticky;
  display: flex;
  align-items: center;
  z-index: 10;
  height: 46px;
  width: 100%;
  font-family: 'Mulish', sans-serif;
  background-color: #0F2925;
}

.container {
  padding: 10px 20px 10px 20px;
}

.marqueeWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title{
  text-decoration: none;
  color:#F5FFF9;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  transition: all 0.5s ease-out;
}

.ukr{
  color:#F9FD46;
}

.title:hover{
  color:#4298f5;
  transition: all 0.5s ease-out;
}

.bird{
  margin-right: 20px;
  width: 24px;
  height: 24px;
}

.arrow{
  width: 20px;
  height: 20px;
  font-size: 50px;
  margin-left: 50px;
}

.arrow svg{
  width: 20px;
  height: 20px;

  margin-left: 50px;
}

@media (min-width: 768px) {
  .Wrapper{
    height: 73px;
  }

  .container{
    padding: 15px 0px 15px 0px;
  }

  .title {
    font-size: 24px;
    text-decoration: none;
  }

  .bird {
    margin-right: 20px;
    width: 40px;
    height: 40px;
  }   

}