.container{
  padding-top: 0;
  padding-bottom: 0;
}

.bgImgWrapper{
  position: absolute;
}

.containerL,
.containerM,
.containerS{
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 666px;
}

.belowImage{
  position: absolute;
  background-color: #0E4B3B;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.img {
  position: absolute;
  right: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display:block;
  z-index: -1;
  object-fit: cover;
}

.imageOverlay {
  position: absolute;
  background-image: linear-gradient(180deg, rgba(11, 72, 49, 0.75) 34.49%, rgba(12, 64, 46, 0.56) 60.67%, rgba(15, 41, 37, 0.00) 100%);
  width: 100%;
  height: 100%;
}

.containerM {
  height: 580px;
}

.containerS {
  height: 496px;
}

.hero{
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.titlebtn{
  margin-top: 20px;
  text-transform: uppercase;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin-top:54px;
}

.title{
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #F5FFF9;
  /* height: 172px; */
}

.description{
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
    line-height: 24px;
  text-align: center;
  color: #F5FFF9;
  margin-top: 20px;
}

.description a{
  color: #F5FFF9;
  text-decoration: none ;
}

.description a:visited{
  color: #F5FFF9;
}

.description a:hover,
.description a:focus{
  color: #F5D1DE;
}

.btnWrapper{
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.btnSecondary {
  margin-top: 15px;
}

.hero .description{
  max-width: 430px;
}

@media (min-width: 343px) {

}

@media (min-width: 463px) {
  .title {
    font-size: 36px;
  }

  .description {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .containerS {
    height: 475px;
  }

  .containerM {
    height: 500px;
  }

  .containerL {
    height: 553px;
  }

  .hero{
    align-items: flex-start;
  }

    .img {
      object-fit: unset;
    }
  .title{
    text-align: left;
    font-size: 40px;
    max-width: 620px;
    /* height: 96px; */
  }

  .description{
    text-align: left;
    font-size: 20px;
    max-width: 591px;
    line-height: 32px;
  }

  .btnWrapper{
    margin-top: 60px;
    flex-direction: row;
  }

  .btnSecondary {
    margin-top: 0px;
    margin-left: 20px;
  }

  .container{
    padding-top: 0;
    padding-bottom: 0;    
    
    align-items: flex-start !important;
  }

  .hero .description{
    max-width: 460px;
  }
}

@media (min-width: 1200px) {
  
  .containerL,
  .containerM,
  .containerS{
    height: 700px;
    background-position: right;
  }

  .containerM {
    height: 600px;
  }
  
  .containerS {
    height: 500px;
  }

  .title{
    line-height: 74px;
    font-size: 64px;
    max-width: 985px;
    /* height: 148px; */
  }

  .description{
    font-size: 24px;
    max-width: 715px;
  }

  .hero .description{
    max-width: 615px;
  }
}